<template>
  <div class="section-wrapper">
    <slot name="actions" />

    <PageSection
      :item="section"
      :index="index"
      :data-tour-step="
        tourIsActive && index === 0 && !fullPageLoading
          ? 'firstSection'
          : undefined
      "
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'SectionWrapper',

  props: {
    section: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    fullPageLoading: get('fullPageLoading'),
    tourIsActive: get('tour/isActive'),
  },

  components: {
    PageSection: () => import('@/components/PageSection'),
  },
}
</script>
